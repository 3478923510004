import React, { useState } from 'react';
import { Dropdown, Button, Container, Grid, Typography } from '@hpstellar/core';

import { Helpers } from '../../../core/src/helpers';
import withPageComponents from '../../../page/components/with-page-components';
import useDeviceInfo from '../../../hooks/useDeviceInfo';

import './care-packs.less';

const carePacks = ({ carepackFinder }) => {
    if (!carepackFinder) return null;

    const [category, setCategory] = useState(null);
    const [family, setFamily] = useState(null);
    const [series, setSeries] = useState(null);
    const { device } = useDeviceInfo(['device']);

    const { title, subtitle, carepacks, gtmAttributes, titleMobile, subtitleMobile } = carepackFinder;
    const displayTitle = device === 'mobile' && titleMobile ? titleMobile : title;
    const displaySubtitle = device === 'mobile' && subtitleMobile ? subtitleMobile : subtitle;

    const categoryChange = value => {
        setCategory(value);
        setFamily(undefined);
        setSeries(undefined);
    };
    const familyChange = value => {
        setFamily(value);
        setSeries(undefined);
    };
    const seriesChange = value => {
        setSeries(value);
    };

    const dropdownProps = {
        customOptionsListProps: {
            className: 'clp-carepack-dropdown-popup',
        },
        customLabel: 'Select',
        nativeLabel: 'Select',
    };

    return (
        <Container className="carepacks">
            <Grid container>
                {displayTitle && (
                    <Typography
                        className="title"
                        variant="display"
                        dangerouslySetInnerHTML={Helpers.createMarkup(displayTitle)}
                    />
                )}
                {displaySubtitle && (
                    <Typography
                        className="subTitle"
                        variant="bodyL"
                        dangerouslySetInnerHTML={Helpers.createMarkup(displaySubtitle)}
                    />
                )}
                <Grid col lg={4}>
                    <Typography className="label" variant="boldL">
                        Select product category
                    </Typography>
                    <Dropdown
                        className="selectorDropdown"
                        variation="form"
                        options={Object.keys(carepacks).map(x => ({ label: x, value: x }))}
                        onChange={categoryChange}
                        defaultValue={category}
                        {...dropdownProps}
                    />
                </Grid>
                <Grid col lg={4}>
                    <Typography className="label" variant="boldL">
                        Select product family
                    </Typography>
                    <Dropdown
                        className="selectorDropdown"
                        variation="form"
                        options={category ? Object.keys(carepacks[category]).map(x => ({ label: x, value: x })) : []}
                        disabled={!category}
                        onChange={familyChange}
                        defaultValue={family}
                        {...dropdownProps}
                    />
                </Grid>
                <Grid col lg={4}>
                    <Typography className="label" variant="boldL">
                        Select product series
                    </Typography>
                    <Dropdown
                        className="selectorDropdown"
                        variation="form"
                        options={
                            family ? Object.keys(carepacks[category][family]).map(x => ({ label: x, value: x })) : []
                        }
                        disabled={!family}
                        onChange={seriesChange}
                        defaultValue={series}
                        {...dropdownProps}
                    />
                </Grid>

                <Grid col lg={12} className="formButtonContainer">
                    <Button
                        className="formButton"
                        disabled={!series}
                        href={series ? carepacks[category][family][series] : ''}
                        {...(gtmAttributes || {})}
                        {...dropdownProps}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default withPageComponents(carePacks, { components: ['carepackFinder'] });
